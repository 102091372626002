import React, { useContext } from 'react';
import { styled, useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline, Typography, Divider, IconButton } from '@mui/material';

import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Loading from './Loading';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getRuta } from '../helpers/Helpers';
import { DataContext } from '../context/DataContext';
import NavBarElements from './NavBarElements';
import { Stack } from '@mui/system';
import { Route, Routes } from 'react-router-dom';
import AccountContainer from '../pages/account/AccountContainer';
import NavLinks from './links/NavLinks';
import HomeContainer from '../pages/home/HomeContainer';
import './estilos/estilos.css';
import { isEmpty } from 'lodash';

import MainLocations from '../pages/settings/locations/MainLocations';
import Municipalities from '../pages/settings/locations/Municipalities';
import { Communities } from '../pages/settings/locations/Communities';
import Unautorized from './Unautorized';
import MainInteresCorriente from '../pages/settings/interesCorriente/MainInteresCorriente';
import PeriodicidadList from '../pages/settings/Periodicidad/PeriodicidadList';
import TasaCambioList from '../pages/settings/tasaCambio/TasaCambioList';
import ClientList from '../pages/clients/ClientList';
import AddClient from '../pages/clients/AddClient';
import { DetalleClientContainer } from '../pages/clients/detalleCliente/DetalleClientContainer';
import CreditLIst from '../pages/credits/CreditLIst';
import AddCredit from '../pages/credits/AddCredit';
import CreditDetailsContainer from '../pages/credits/creditDetails/CreditDetailsContainer';
import Userlist from '../pages/security/users/Userlist';
import RolList from '../pages/security/rols/RolList';
import AddUser from '../pages/security/users/AddUser';
import EditUser from '../pages/security/users/EditUser';
import AddRol from '../pages/security/rols/AddRol';
import EditRol from '../pages/security/rols/EditRol';
import HolidayList from '../pages/settings/holidays/HolidayList';
import Reports from '../pages/reports/Reports';

import ClientesGestor from '../pages/reports/reportesCartera/ClientesGestor';
import CreditsByGestor from '../pages/reports/reportesCartera/CreditsByGestor';
import CobroDiario from '../pages/reports/reportesCartera/CobroDiario';
import Recuperacion from '../pages/reports/reportesCartera/Recuperacion';
import Desembolsos from '../pages/reports/reportesCartera/Desembolsos';
import SaldosCartera from '../pages/reports/reportesCartera/SaldosCartera';
import { getUser } from '../api/Account';
import DefeatedCredits from '../pages/reports/reportesCartera/DefeatedCredits';
import AccountStatus from '../pages/reports/reportesCartera/AccountStatus';
import Caja from '../pages/admon/caja/Caja';
import Gastos from '../pages/admon/Gastos';
import CierreCaja from '../pages/reports/reportesCartera/CierreCaja';
import CierreCajaDetallado from '../pages/reports/reportesCartera/CierreCajaDetallado';
import EstadoResultado from '../pages/reports/reportesCartera/EstadoResultado';
import ConceptoGastoList from '../pages/settings/gastos/ConceptoGastoList';
import ServerRollist from '../pages/security/server/ServerRollist';
import CreditsCancelled from '../pages/reports/reportesCartera/CreditsCancelled';
import VencimientosProyectados from '../pages/reports/reportesCartera/VencimientosProyectados';
import SucursalList from '../pages/settings/sucursals/SucursalList';
import ReporteUserTracking from '../pages/reports/reportesCartera/ReporteUserTracking';
import RecuperacionByCLient from '../pages/reports/reportesCartera/RecuperacionByCLient';

const drawerWidth = 250;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen
	}),
	overflowX: 'hidden'
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(6)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(6)} + 1px)`
	}
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar
}));

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	})
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme)
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme)
	})
}));

export default function MiniDrawer() {
	const { isDarkMode, access, docTitle, version, setIsLogged } = useContext(DataContext);
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);

	const user = getUser();

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	let rout = getRuta();

	const tema = createTheme({
		typography: {
			fontFamily: ['Agency FB', 'serif'].join(',')
		},

		palette: {
			mode: isDarkMode ? 'dark' : 'light',
			background: {
				default: isDarkMode ? '#0A1929' : '#fff'
			}
		},
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: 20
					}
				}
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: { borderRadius: 30 }
				}
			},
			MuiPaper: {
				defaultProps: {
					elevation: 20
				},
				styleOverrides: {
					root: {
						backgroundColor: isDarkMode ? '#0A1929' : '#fff',
						borderRadius: 30
					}
				}
			},
			MuiAppBar: {
				styleOverrides: {
					root: {
						backgroundColor: isDarkMode ? 'rgba(0, 20, 50, 0.8)' : '#0A1929C3',
						backdropFilter: 'blur(3px)',
						borderRadius: 0
					}
				}
			},
			MuiDrawer: {
				styleOverrides: {
					paper: {
						backgroundColor: isDarkMode ? '#0A1929' : '#fff',
						borderRadius: 0
					}
				}
			},
			MuiMenu: {
				styleOverrides: {
					list: {
						backgroundColor: isDarkMode ? '#0A1929' : '#fff'
					}
				}
			}
		}
	});

	if (isEmpty(access)) {
		return <Loading />;
	}

	if (user === null) {
		setIsLogged(false);
		return;
	}

	return (
		<>
			<ThemeProvider theme={tema}>
				<Box sx={{ display: 'flex' }}>
					<CssBaseline />
					<AppBar position="fixed" open={open}>
						<Toolbar>
							<IconButton
								aria-label="open drawer"
								onClick={handleDrawerOpen}
								edge="start"
								sx={{
									marginRight: 5,
									...(open && { display: 'none' })
								}}
							>
								<ChevronRightIcon />
							</IconButton>
							<NavBarElements />
						</Toolbar>
					</AppBar>

					<Drawer variant="permanent" open={open}>
						<DrawerHeader>
							<Stack marginRight={5}>
								<Typography fontSize={18} textAlign={'center'}>
									{docTitle}
								</Typography>
								<Typography fontSize={15} textAlign={'center'}>
									{version}
								</Typography>
							</Stack>

							<IconButton onClick={handleDrawerClose}>
								{theme.direction === 'rtl' ? (
									<ChevronRightIcon />
								) : (
									<ChevronLeftIcon />
								)}
							</IconButton>
						</DrawerHeader>
						<Divider />
						<NavLinks drawerIsOpen={open} />
					</Drawer>

					<Box
						component="main"
						sx={{
							flexGrow: 1,
							p: 3,
							bgcolor: 'background.default',
							height: '100vh',
							overflow: 'auto'
						}}
					>
						<DrawerHeader />
						<Routes>
							<Route path={`${rout}/`} element={<HomeContainer />} />
							{/* Rulas Clientes */}
							<Route path={`${rout}/clients`} element={<ClientList />} />
							<Route path={`${rout}/addclient`} element={<AddClient />} />
							<Route
								path={`${rout}/client/:id`}
								element={<DetalleClientContainer />}
							/>
							{/* Rulas Creditos */}
							<Route path={`${rout}/credits`} element={<CreditLIst />} />
							<Route path={`${rout}/credit/add/:id`} element={<AddCredit />} />
							<Route
								path={`${rout}/credit/:id`}
								element={<CreditDetailsContainer />}
							/>
							{/* Rulas Admon */}
							<Route path={`${rout}/caja`} element={<Caja />} />
							{/* <Route path={`${rout}/caja-chica`} element={<Admon />} /> */}
							<Route path={`${rout}/gastos`} element={<Gastos />} />
							{/* <Route path={`${rout}/contabilidad`} element={<Contabilidad />} /> */}
							{/* Rulas Reportes */}
							<Route path={`${rout}/reports`} element={<Reports />} />
							<Route
								path={`${rout}/r-clientsgestor/:selectedSucursal/:selectedStatus/:selectedSortBy`}
								element={<ClientesGestor />}
							/>
							<Route
								path={`${rout}/r-creditsbyGestor/:selectedSucursal/:selectedGestor/:selectedSortBy`}
								element={<CreditsByGestor />}
							/>
							<Route
								path={`${rout}/r-cobrodiario/:selectedSucursal/:selectedGestor/:selectedFecha`}
								element={<CobroDiario />}
							/>
							<Route
								path={`${rout}/r-recuperacion/:selectedSucursal/:selectedGestor/:fechaDesde/:fechaHasta`}
								element={<Recuperacion />}
							/>
							<Route
								path={`${rout}/r-recuperacionbyclient/:selectedSucursal/:selectedGestor/:fechaDesde/:fechaHasta`}
								element={<RecuperacionByCLient />}
							/>
							<Route
								path={`${rout}/r-desembolsos/:selectedSucursal/:selectedGestor/:fechaDesde/:fechaHasta`}
								element={<Desembolsos />}
							/>
							<Route
								path={`${rout}/r-saldoscartera/:selectedSucursal/:selectedGestor`}
								element={<SaldosCartera />}
							/>
							{/* <Route
								path={`${rout}/r-saldoscartera/:selectedSucursal/:selectedGestor/:selectedFecha`}
								element={<SaldosCartera />}
							/> */}
							<Route
								path={`${rout}/r-defeatedcredits/:selectedSucursal/:selectedGestor/:selectedSortBy`}
								element={<DefeatedCredits />}
							/>
							<Route
								path={`${rout}/r-proyecteddefeatedcredits/:selectedSucursal/:selectedGestor/:fechaDesde/:fechaHasta/:selectedSortBy`}
								element={<VencimientosProyectados />}
							/>
							<Route
								path={`${rout}/r-accountstatus/:selectedCredit`}
								element={<AccountStatus />}
							/>
							<Route
								path={`${rout}/r-cierrecaja/:selectedSucursal/:fecha`}
								element={<CierreCaja />}
							/>
							<Route
								path={`${rout}/r-cierrecajad/:selectedSucursal/:fecha`}
								element={<CierreCajaDetallado />}
							/>
							<Route
								path={`${rout}/r-estadoresultado/:selectedSucursal/:fechaDesde/:fechaHasta`}
								element={<EstadoResultado />}
							/>
							<Route
								path={`${rout}/r-creditscancelled/:selectedSucursal/:selectedGestor/:fechaDesde/:fechaHasta/:justNoReNew`}
								element={<CreditsCancelled />}
							/>
							<Route
								path={`${rout}/r-usertracking/:selectedSucursal/:selectedGestor`}
								element={<ReporteUserTracking />}
							/>
							{/* Rulas Security-users */}
							<Route path={`${rout}/users`} element={<Userlist />} />
							<Route path={`${rout}/adduser`} element={<AddUser />} />
							<Route path={`${rout}/user/:id`} element={<EditUser />} />
							{/* Rulas Security-rols */}
							<Route path={`${rout}/rols`} element={<RolList />} />
							<Route path={`${rout}/addrol`} element={<AddRol />} />
							<Route path={`${rout}/rol/:id`} element={<EditRol />} />
							{/* Rulas Security-server */}
							<Route path={`${rout}/server`} element={<ServerRollist />} />
							<Route path={`${rout}/account`} element={<AccountContainer />} />
							{/* Rulas Ubicaciones */}
							<Route path={`${rout}/sucursales`} element={<SucursalList />} />s
							<Route path={`${rout}/locations`} element={<MainLocations />} />
							<Route path={`${rout}/department/:id`} element={<Municipalities />} />
							<Route
								path={`${rout}/locations/municipalities/:id`}
								element={<Communities />}
							/>
							<Route
								path={`${rout}/locations/municipalities/:id`}
								element={<Communities />}
							/>
							<Route
								path={`${rout}/tasa-interes`}
								element={<MainInteresCorriente />}
							/>
							<Route path={`${rout}/periodicidad`} element={<PeriodicidadList />} />
							<Route path={`${rout}/holidays`} element={<HolidayList />} />
							<Route
								path={`${rout}/conceptogastos`}
								element={<ConceptoGastoList />}
							/>
							<Route path={`${rout}/tasa-cambio`} element={<TasaCambioList />} />
						</Routes>
					</Box>
				</Box>
				<Loading />
			</ThemeProvider>
			<Unautorized />
		</>
	);
}
