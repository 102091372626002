import React, { useState, useEffect, useContext, useRef } from 'react';
import {
	Container,
	Stack,
	AppBar,
	Toolbar,
	Typography,
	Dialog,
	IconButton,
	TableContainer,
	Table,
	TableCell,
	TableRow,
	TableHead,
	TableBody,
	Divider
} from '@mui/material';
import { isEmpty } from 'lodash';

import { useParams } from 'react-router-dom';
import { DataContext } from '../../../context/DataContext';

import { getRol, getToken, getUser } from '../../../api/Account';
import ReactToPrint from 'react-to-print';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import NoData from '../../../components/NoData';
import { getDesembolsosAsync } from '../../../api/Report';
import { PrintReport } from '../../../components/PrintReport';
import moment from 'moment';
import {
	getGestoresAsync,
	getGestoresBySucursalAccessAsync,
	getSucursalByUserAsync
} from '../../../api/Users';
import { simpleMessage } from '../../../helpers/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx/xlsx.mjs';

const Desembolsos = () => {
	const [data, setData] = useState([]);

	const [sucursalName, setSucursalName] = useState('');
	const [sucursalList, setSucursalList] = useState([]);

	const [gestorName, setGestorName] = useState('');
	const [gestorList, setGestorList] = useState([]);

	const compRef = useRef();
	const { selectedSucursal, selectedGestor, fechaDesde, fechaHasta } = useParams();

	const { setIsLoading, setIsUnautorized, title, slogan, icon } = useContext(DataContext);

	const token = getToken();
	const user = getUser();
	const rol = getRol();

	useEffect(() => {
		(async () => {
			let sucursals = await getSucursals();

			if (selectedSucursal === 't') {
				setSucursalName('Todas');
			} else {
				let name = sucursals.filter((item) => item.id.toString() === selectedSucursal);
				setSucursalName(name[0].description);
			}

			const data = {
				desde: fechaDesde.toString(),
				hasta: fechaHasta.toString(),
				gestorId: selectedGestor === 't' ? 0 : selectedGestor,
				sucursalId: selectedSucursal === 't' ? 0 : selectedSucursal
			};

			setIsLoading(true);
			const result = await getDesembolsosAsync(token, data);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setIsLoading(false);
			setData(result.data.result);
		})();
	}, []);

	const getSucursals = async () => {
		setIsLoading(true);
		const result = await getSucursalByUserAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setSucursalList(
			selectedSucursal === 't'
				? result.data.result
				: result.data.result.filter((g) => g.id.toString() === selectedSucursal)
		);

		let gestores = await getGestores(selectedSucursal);

		if (selectedGestor === 't') {
			setGestorName('Todos');
		} else {
			let name = gestores.filter((item) => item.id.toString() === selectedGestor);

			setGestorName(name[0].fullName);
		}
		return result.data.result;
	};

	const getGestores = async (sucursal) => {
		setIsLoading(true);
		const result =
			sucursal === 't'
				? await getGestoresBySucursalAccessAsync(token)
				: await getGestoresAsync(token, sucursal);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}

		setIsLoading(false);
		setGestorList(result.data.result);
		return result.data.result;
	};

	const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page { size: landscape;  margin: 5mm; }
`;

	let totalCreditsConsol = 0;
	let totalCapConsol = 0;
	let totalICConsol = 0;
	let totalMontoPagaConsol = 0;

	let totalNuevosConsol = 0;
	let totalRecurrentesConsol = 0;

	const exportExcel = () => {
		let exp = [];
		data.map((item) => {
			const dataGestor = gestorList.filter((gestor) => gestor.id === item.gestor);
			let gestor = dataGestor[0];
			console.log('🚀  gestor:', gestor);
			let obj = {
				fechaEntrega: moment(item.fechaEntrega).format('L'),
				cliente: item.fullName,
				tipo: item.tipoCliente,
				principal: item.montoPrincipal,
				interesCorriente: item.interesCorriente,
				montoPagar: item.montoPagar,
				fechaPrimeraCuota: moment(item.fechaPrimeraCuota).format('L'),
				cuotaInteresCorriente: item.cuotaInteresCorriente,
				cuotaCapital: item.cuotaPrincipal,
				cuotaPagar: item.cuotaPagar,
				periodicidad: item.periodicidad,
				plazo: item.plazo,
				isCancelado: item.isCanceled,
				gestor: gestor ? 'Gestor Inactivo' : gestor.fullName,
				sucursal: item.sucursal
			};
			exp.push(obj);
		});
		var bk = XLSX.utils.book_new(),
			bs = XLSX.utils.json_to_sheet(exp);

		XLSX.utils.book_append_sheet(bk, bs, 'desembolsos');
		XLSX.writeFile(bk, 'Desembolsos.xlsx');
	};

	return (
		<div>
			<Dialog fullScreen open={true}>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
						<Typography
							sx={{ ml: 2, flex: 1, textAlign: 'center' }}
							variant="h5"
							component="div"
						>
							{`${title} `}
						</Typography>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
					</Toolbar>
				</AppBar>

				<Stack display="flex" justifyContent="center">
					<Stack
						direction={'row'}
						justifyContent="space-between"
						paddingLeft={5}
						paddingRight={5}
						marginTop={1}
						marginBottom={1}
					>
						<IconButton variant="outlined" onClick={() => exportExcel()}>
							<FontAwesomeIcon
								icon={faCloudArrowDown}
								style={{ fontSize: 40, color: '#00a152', width: 40 }}
							/>
						</IconButton>

						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center',
									fontWeight: 'bold'
								}}
							>
								{slogan}
							</Typography>

							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center',
									fontWeight: 'bold'
								}}
								variant="h6"
								component="div"
							>
								Reporte de Desembolsos
							</Typography>
						</Stack>

						<ReactToPrint
							trigger={() => {
								return (
									<IconButton
										variant="outlined"
										// style={{ position: "fixed", right: 20, top: 75 }}
									>
										<PrintRoundedIcon
											style={{ fontSize: 40, color: '#2979ff', width: 40 }}
										/>
									</IconButton>
								);
							}}
							content={() => compRef.current}
							pageStyle={pageStyle}
						/>
					</Stack>

					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<Stack direction={'row'} justifyContent="space-between">
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Parametros:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Sucursal: ${
										selectedSucursal === 't' ? 'Todas' : sucursalName
									}`}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Gestor Seleccionado: ${
										selectedGestor === 't' ? 'Todos' : gestorName
									}`}
								</Typography>

								<Typography fontSize={11} textAlign="left">
									{`Desde: ${moment(fechaDesde).format('L')} - Hasta:  ${moment(
										fechaHasta
									).format('L')}`}
								</Typography>
							</Stack>

							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Usuario:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{user}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{rol}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
								</Typography>
							</Stack>
						</Stack>
					</Container>
				</Stack>

				<Container maxWidth={false} sx={{ textAlign: 'center' }}>
					<hr />
					{isEmpty(data) ? (
						<NoData />
					) : (
						<>
							{sucursalList.map((suc) => {
								const sSucursal = data.filter(
									(c) => c.sucursal === suc.description
								);

								return (
									<Stack key={suc.description}>
										<Stack direction="row" spacing={1}>
											<Typography
												sx={{
													color: '#2196f3',
													textAlign: 'left'
												}}
												fontSize={11}
											>
												Sucursal:
											</Typography>
											<Typography
												sx={{
													textAlign: 'left'
												}}
												fontSize={11}
											>
												{`${suc.description}`}
											</Typography>
										</Stack>
										{gestorList.map((gestor) => {
											const cGestor = sSucursal.filter((c) => {
												return c.gestor === gestor.id;
											});
											let totalCap = 0;
											let totalIC = 0;
											let montoPagar = 0;

											let totalNuevos = 0;
											let totalRecurrentes = 0;

											cGestor.map((c) => {
												totalCap += c.montoPrincipal;
												totalIC += c.montoPagar - c.montoPrincipal;
												montoPagar += c.montoPagar;

												if (c.tipoCliente === 'N') {
													totalNuevos += 1;
												} else if (c.tipoCliente === 'R') {
													totalRecurrentes += 1;
												}
											});

											totalCreditsConsol += cGestor.length;
											totalCapConsol += totalCap;
											totalICConsol += totalIC;
											totalMontoPagaConsol += montoPagar;

											totalNuevosConsol += totalNuevos;
											totalRecurrentesConsol += totalRecurrentes;

											return isEmpty(cGestor) ? (
												<></>
											) : (
												<Stack key={gestor.id}>
													<Stack direction="row" spacing={1}>
														<Typography
															sx={{
																color: '#2196f3',
																textAlign: 'left'
															}}
															fontSize={11}
														>
															Gestor:
														</Typography>
														<Typography
															sx={{
																textAlign: 'left'
															}}
															fontSize={11}
														>
															{gestor.fullName}
														</Typography>
													</Stack>
													<Divider />

													{isEmpty(cGestor) ? (
														<>
															<Typography
																sx={{
																	color: '#2196f3',
																	textAlign: 'center',
																	fontWeight: 'bold'
																}}
																fontSize={15}
															>
																Gestor no tiene desembolsos!
															</Typography>
															<hr />
														</>
													) : (
														<>
															<TableContainer>
																<Table
																	aria-label="simple table"
																	size="small"
																>
																	<TableHead>
																		<TableRow>
																			<TableCell
																				align="center"
																				className="text-primary fw-bold"
																				style={{
																					fontSize: 11
																				}}
																			>
																				F.Entrega
																			</TableCell>

																			<TableCell
																				align="left"
																				className="text-primary fw-bold"
																				style={{
																					fontSize: 11
																				}}
																			>
																				Cliente
																			</TableCell>

																			<TableCell
																				align="center"
																				className="text-primary fw-bold"
																				style={{
																					fontSize: 11
																				}}
																			>
																				Tipo
																			</TableCell>

																			<TableCell
																				align="center"
																				className="text-primary fw-bold"
																				style={{
																					fontSize: 11
																				}}
																			>
																				Principal
																			</TableCell>

																			<TableCell
																				align="center"
																				className="text-primary fw-bold"
																				style={{
																					fontSize: 11
																				}}
																			>
																				I.Corriente
																			</TableCell>

																			<TableCell
																				align="center"
																				className="text-primary fw-bold"
																				style={{
																					fontSize: 11
																				}}
																			>
																				M.Pagar
																			</TableCell>

																			<TableCell
																				align="center"
																				className="text-primary fw-bold"
																				style={{
																					fontSize: 11
																				}}
																			>
																				F.Cuota1
																			</TableCell>

																			<TableCell
																				align="center"
																				className="text-primary fw-bold"
																				style={{
																					fontSize: 11
																				}}
																			>
																				C.Int.Corriente
																			</TableCell>

																			<TableCell
																				align="center"
																				className="text-primary fw-bold"
																				style={{
																					fontSize: 11
																				}}
																			>
																				C.Capital
																			</TableCell>

																			<TableCell
																				align="center"
																				className="text-primary fw-bold"
																				style={{
																					fontSize: 11
																				}}
																			>
																				C.Pagar
																			</TableCell>

																			<TableCell
																				align="center"
																				className="text-primary fw-bold"
																				style={{
																					fontSize: 11
																				}}
																			>
																				Periodicidad
																			</TableCell>

																			<TableCell
																				align="center"
																				className="text-primary fw-bold"
																				style={{
																					fontSize: 11
																				}}
																			>
																				Plazo
																			</TableCell>

																			<TableCell
																				align="center"
																				className="text-primary fw-bold"
																				style={{
																					fontSize: 11
																				}}
																			>
																				Cancelado
																			</TableCell>
																		</TableRow>
																	</TableHead>
																	<TableBody>
																		{cGestor.map((row) => {
																			return (
																				<TableRow
																					key={row.id}
																				>
																					<TableCell
																						align="center"
																						component="th"
																						scope="row"
																						style={{
																							fontSize: 9
																						}}
																					>
																						{moment(
																							row.fechaEntrega
																						).format(
																							'L'
																						)}
																					</TableCell>

																					<TableCell
																						align="left"
																						style={{
																							fontSize: 9
																						}}
																					>
																						{
																							row.fullName
																						}
																					</TableCell>

																					<TableCell
																						align="center"
																						style={{
																							fontSize: 9
																						}}
																					>
																						{
																							row.tipoCliente
																						}
																					</TableCell>

																					<TableCell
																						align="center"
																						style={{
																							fontSize: 9
																						}}
																					>
																						{new Intl.NumberFormat(
																							'es-NI',
																							{
																								style: 'currency',
																								currency:
																									'NIO'
																							}
																						).format(
																							row.montoPrincipal
																						)}
																					</TableCell>

																					<TableCell
																						align="center"
																						style={{
																							fontSize: 9
																						}}
																					>{`${row.interesCorriente.toFixed(
																						2
																					)}%`}</TableCell>

																					<TableCell
																						align="center"
																						style={{
																							fontSize: 9
																						}}
																					>
																						{new Intl.NumberFormat(
																							'es-NI',
																							{
																								style: 'currency',
																								currency:
																									'NIO'
																							}
																						).format(
																							row.montoPagar
																						)}
																					</TableCell>

																					<TableCell
																						align="center"
																						style={{
																							fontSize: 9
																						}}
																					>
																						{moment(
																							row.fechaPrimeraCuota
																						).format(
																							'L'
																						)}
																					</TableCell>

																					<TableCell
																						align="center"
																						style={{
																							fontSize: 9
																						}}
																					>
																						{new Intl.NumberFormat(
																							'es-NI',
																							{
																								style: 'currency',
																								currency:
																									'NIO'
																							}
																						).format(
																							row.cuotaInteresCorriente
																						)}
																					</TableCell>

																					<TableCell
																						align="center"
																						style={{
																							fontSize: 9
																						}}
																					>
																						{new Intl.NumberFormat(
																							'es-NI',
																							{
																								style: 'currency',
																								currency:
																									'NIO'
																							}
																						).format(
																							row.cuotaPrincipal
																						)}
																					</TableCell>

																					<TableCell
																						align="center"
																						style={{
																							fontSize: 9
																						}}
																					>
																						{new Intl.NumberFormat(
																							'es-NI',
																							{
																								style: 'currency',
																								currency:
																									'NIO'
																							}
																						).format(
																							row.cuotaPagar
																						)}
																					</TableCell>

																					<TableCell
																						align="center"
																						style={{
																							fontSize: 9
																						}}
																					>
																						{
																							row.periodicidad
																						}
																					</TableCell>

																					<TableCell
																						align="center"
																						style={{
																							fontSize: 9
																						}}
																					>
																						{row.plazo}
																					</TableCell>

																					<TableCell
																						align="center"
																						style={{
																							fontSize: 9
																						}}
																					>
																						{row.isCanceled
																							? 'SI'
																							: 'NO'}
																					</TableCell>
																				</TableRow>
																			);
																		})}
																	</TableBody>
																</Table>
															</TableContainer>

															<hr />

															<Stack
																direction="row"
																flex="row"
																justifyContent="space-around"
															>
																<Stack textAlign="center">
																	<Typography
																		style={{
																			fontWeight: 'bold',
																			color: '#03a9f4',
																			fontSize: 11
																		}}
																	>
																		Total Desembolsos
																	</Typography>
																	<Typography
																		style={{ fontSize: 11 }}
																	>
																		{cGestor.length}
																	</Typography>
																</Stack>

																<Stack textAlign="center">
																	<Typography
																		style={{
																			fontWeight: 'bold',
																			color: '#03a9f4',
																			fontSize: 11
																		}}
																	>
																		Total Nuevos
																	</Typography>
																	<Typography
																		style={{ fontSize: 11 }}
																	>
																		{totalNuevos}
																	</Typography>
																</Stack>

																<Stack textAlign="center">
																	<Typography
																		style={{
																			fontWeight: 'bold',
																			color: '#03a9f4',
																			fontSize: 11
																		}}
																	>
																		Total Recurrentes
																	</Typography>
																	<Typography
																		style={{ fontSize: 11 }}
																	>
																		{totalRecurrentes}
																	</Typography>
																</Stack>

																<Stack textAlign="center">
																	<Typography
																		style={{
																			fontWeight: 'bold',
																			color: '#03a9f4',
																			fontSize: 11
																		}}
																	>
																		Total Principal
																	</Typography>
																	<Typography
																		style={{ fontSize: 11 }}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(totalCap)}
																	</Typography>
																</Stack>

																<Stack textAlign="center">
																	<Typography
																		style={{
																			fontWeight: 'bold',
																			color: '#03a9f4',
																			fontSize: 11
																		}}
																	>
																		Total Interes Corriente
																	</Typography>
																	<Typography
																		style={{ fontSize: 11 }}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(totalIC)}
																	</Typography>
																</Stack>

																<Stack textAlign="center">
																	<Typography
																		style={{
																			fontWeight: 'bold',
																			color: '#03a9f4',
																			fontSize: 11
																		}}
																	>
																		Total Monto a Pagar
																	</Typography>
																	<Typography
																		style={{ fontSize: 11 }}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(montoPagar)}
																	</Typography>
																</Stack>
															</Stack>

															<hr />
														</>
													)}
												</Stack>
											);
										})}
									</Stack>
								);
							})}
						</>
					)}

					{gestorList.length === 1 ? (
						<></>
					) : (
						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center'
								}}
							>
								Consolidado
							</Typography>

							<hr />

							<Stack direction="row" flex="row" justifyContent="space-around">
								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total Cobros
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{totalCreditsConsol}
									</Typography>
								</Stack>

								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total Nuevos
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{totalNuevosConsol}
									</Typography>
								</Stack>

								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total Recurrentes
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{totalRecurrentesConsol}
									</Typography>
								</Stack>

								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total Principal
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(totalCapConsol)}
									</Typography>
								</Stack>

								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total Interes Corriente
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(totalICConsol)}
									</Typography>
								</Stack>

								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total Monto a Pagar
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(totalMontoPagaConsol)}
									</Typography>
								</Stack>
							</Stack>
							<hr />
						</Stack>
					)}
				</Container>
			</Dialog>

			<div
				style={{
					display: 'none'
				}}
			>
				<PrintReport
					ref={compRef}
					titulo={'Reporte de Desembolsos'}
					fecha={`Fecha: ${moment().format('L')}`}
				>
					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<Stack direction={'row'} justifyContent="space-between">
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Parametros:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Sucursal: ${
										selectedSucursal === 't' ? 'Todas' : sucursalName
									}`}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Gestor Seleccionado: ${
										selectedGestor === 't' ? 'Todos' : gestorName
									}`}
								</Typography>

								<Typography fontSize={11} textAlign="left">
									{`Desde: ${moment(fechaDesde).format('L')} - Hasta:  ${moment(
										fechaHasta
									).format('L')}`}
								</Typography>
							</Stack>
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Usuario:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{user}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{rol}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
								</Typography>
							</Stack>
						</Stack>

						<hr />
						{isEmpty(data) ? (
							<NoData />
						) : (
							<>
								{gestorList.map((gestor) => {
									const cGestor = data.filter((c) => {
										return c.gestor === gestor.id;
									});
									let totalCap = 0;
									let totalIC = 0;
									let montoPagar = 0;

									let totalNuevos = 0;
									let totalRecurrentes = 0;

									cGestor.map((c) => {
										totalCap += c.montoPrincipal;
										totalIC += c.montoPagar - c.montoPrincipal;
										montoPagar += c.montoPagar;

										if (c.tipoCliente === 'N') {
											totalNuevos += 1;
										} else if (c.tipoCliente === 'R') {
											totalRecurrentes += 1;
										}
									});

									return (
										<Stack key={gestor.id}>
											<Stack direction="row" spacing={1}>
												<Typography
													sx={{
														color: '#2196f3',
														textAlign: 'left'
													}}
													fontSize={11}
												>
													Gestor:
												</Typography>
												<Typography
													sx={{
														textAlign: 'left'
													}}
													fontSize={11}
												>
													{gestor.fullName}
												</Typography>
											</Stack>
											<Divider />

											{isEmpty(cGestor) ? (
												<>
													<Typography
														sx={{
															color: '#2196f3',
															textAlign: 'center',
															fontWeight: 'bold'
														}}
														fontSize={15}
													>
														Gestor no tiene desembolsos!
													</Typography>
													<hr />
												</>
											) : (
												<>
													<TableContainer>
														<Table
															aria-label="simple table"
															size="small"
														>
															<TableHead>
																<TableRow>
																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																		style={{ fontSize: 9 }}
																	>
																		F.Entrega
																	</TableCell>

																	<TableCell
																		align="left"
																		className="text-primary fw-bold"
																		style={{ fontSize: 9 }}
																	>
																		Cliente
																	</TableCell>

																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																		style={{ fontSize: 9 }}
																	>
																		Tipo
																	</TableCell>

																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																		style={{ fontSize: 9 }}
																	>
																		Principal
																	</TableCell>

																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																		style={{ fontSize: 9 }}
																	>
																		I.Corriente
																	</TableCell>

																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																		style={{ fontSize: 9 }}
																	>
																		M.Pagar
																	</TableCell>

																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																		style={{ fontSize: 9 }}
																	>
																		F.Cuota1
																	</TableCell>

																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																		style={{ fontSize: 9 }}
																	>
																		C.Int.Corriente
																	</TableCell>

																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																		style={{ fontSize: 9 }}
																	>
																		C.Capital
																	</TableCell>

																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																		style={{ fontSize: 9 }}
																	>
																		C.Pagar
																	</TableCell>

																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																		style={{ fontSize: 9 }}
																	>
																		Periodicidad
																	</TableCell>

																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																		style={{ fontSize: 9 }}
																	>
																		Plazo
																	</TableCell>

																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																		style={{ fontSize: 9 }}
																	>
																		Cancelado
																	</TableCell>
																</TableRow>
															</TableHead>
															{cGestor.map((row) => {
																return (
																	<TableRow key={row.id}>
																		<TableCell
																			align="center"
																			component="th"
																			scope="row"
																			style={{ fontSize: 7 }}
																			className="text-dark"
																		>
																			{moment(
																				row.fechaEntrega
																			).format('L')}
																		</TableCell>

																		<TableCell
																			align="left"
																			style={{ fontSize: 7 }}
																			className="text-dark"
																		>
																			{row.fullName}
																		</TableCell>

																		<TableCell
																			align="center"
																			style={{ fontSize: 7 }}
																			className="text-dark"
																		>
																			{row.tipoCliente}
																		</TableCell>

																		<TableCell
																			align="center"
																			style={{ fontSize: 7 }}
																			className="text-dark"
																		>
																			{new Intl.NumberFormat(
																				'es-NI',
																				{
																					style: 'currency',
																					currency: 'NIO'
																				}
																			).format(
																				row.montoPrincipal
																			)}
																		</TableCell>

																		<TableCell
																			align="center"
																			style={{ fontSize: 7 }}
																			className="text-dark"
																		>{`${row.interesCorriente}%`}</TableCell>

																		<TableCell
																			align="center"
																			style={{ fontSize: 7 }}
																			className="text-dark"
																		>
																			{new Intl.NumberFormat(
																				'es-NI',
																				{
																					style: 'currency',
																					currency: 'NIO'
																				}
																			).format(
																				row.montoPagar
																			)}
																		</TableCell>

																		<TableCell
																			align="center"
																			style={{ fontSize: 7 }}
																			className="text-dark"
																		>
																			{moment(
																				row.fechaPrimeraCuota
																			).format('L')}
																		</TableCell>

																		<TableCell
																			align="center"
																			style={{ fontSize: 7 }}
																			className="text-dark"
																		>
																			{new Intl.NumberFormat(
																				'es-NI',
																				{
																					style: 'currency',
																					currency: 'NIO'
																				}
																			).format(
																				row.cuotaInteresCorriente
																			)}
																		</TableCell>

																		<TableCell
																			align="center"
																			style={{ fontSize: 7 }}
																			className="text-dark"
																		>
																			{new Intl.NumberFormat(
																				'es-NI',
																				{
																					style: 'currency',
																					currency: 'NIO'
																				}
																			).format(
																				row.cuotaPrincipal
																			)}
																		</TableCell>

																		<TableCell
																			align="center"
																			style={{ fontSize: 7 }}
																			className="text-dark"
																		>
																			{new Intl.NumberFormat(
																				'es-NI',
																				{
																					style: 'currency',
																					currency: 'NIO'
																				}
																			).format(
																				row.cuotaPagar
																			)}
																		</TableCell>

																		<TableCell
																			align="center"
																			style={{ fontSize: 7 }}
																			className="text-dark"
																		>
																			{row.periodicidad}
																		</TableCell>

																		<TableCell
																			align="center"
																			style={{ fontSize: 7 }}
																			className="text-dark"
																		>
																			{row.plazo}
																		</TableCell>

																		<TableCell
																			align="center"
																			style={{ fontSize: 7 }}
																			className="text-dark"
																		>
																			{row.isCanceled
																				? 'SI'
																				: 'NO'}
																		</TableCell>
																	</TableRow>
																);
															})}
														</Table>
													</TableContainer>

													<hr />
													<Stack
														direction="row"
														flex="row"
														justifyContent="space-around"
													>
														<Stack textAlign="center">
															<Typography
																style={{
																	fontWeight: 'bold',
																	color: '#03a9f4',
																	fontSize: 10
																}}
															>
																Total Desembolsos
															</Typography>
															<Typography style={{ fontSize: 9 }}>
																{cGestor.length}
															</Typography>
														</Stack>

														<Stack textAlign="center">
															<Typography
																style={{
																	fontWeight: 'bold',
																	color: '#03a9f4',
																	fontSize: 10
																}}
															>
																Total Nuevos
															</Typography>
															<Typography style={{ fontSize: 10 }}>
																{totalNuevos}
															</Typography>
														</Stack>

														<Stack textAlign="center">
															<Typography
																style={{
																	fontWeight: 'bold',
																	color: '#03a9f4',
																	fontSize: 10
																}}
															>
																Total Recurrentes
															</Typography>
															<Typography style={{ fontSize: 10 }}>
																{totalRecurrentes}
															</Typography>
														</Stack>

														<Stack textAlign="center">
															<Typography
																style={{
																	fontWeight: 'bold',
																	color: '#03a9f4',
																	fontSize: 10
																}}
															>
																Total Principal
															</Typography>
															<Typography style={{ fontSize: 9 }}>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(totalCap)}
															</Typography>
														</Stack>

														<Stack textAlign="center">
															<Typography
																style={{
																	fontWeight: 'bold',
																	color: '#03a9f4',
																	fontSize: 10
																}}
															>
																Total Interes Corriente
															</Typography>
															<Typography style={{ fontSize: 9 }}>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(totalIC)}
															</Typography>
														</Stack>

														<Stack textAlign="center">
															<Typography
																style={{
																	fontWeight: 'bold',
																	color: '#03a9f4',
																	fontSize: 10
																}}
															>
																Total Monto a Pagar
															</Typography>
															<Typography style={{ fontSize: 9 }}>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(montoPagar)}
															</Typography>
														</Stack>
													</Stack>
													<hr />
												</>
											)}
										</Stack>
									);
								})}
							</>
						)}

						{gestorList.length === 1 ? (
							<></>
						) : (
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'center'
									}}
								>
									Consolidado
								</Typography>

								<hr />

								<Stack direction="row" flex="row" justifyContent="space-around">
									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 10
											}}
										>
											Total Cobros
										</Typography>
										<Typography style={{ fontSize: 9 }}>
											{totalCreditsConsol}
										</Typography>
									</Stack>

									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 10
											}}
										>
											Total Nuevos
										</Typography>
										<Typography style={{ fontSize: 10 }}>
											{totalNuevosConsol}
										</Typography>
									</Stack>

									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 10
											}}
										>
											Total Recurrentes
										</Typography>
										<Typography style={{ fontSize: 10 }}>
											{totalRecurrentesConsol}
										</Typography>
									</Stack>

									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 10
											}}
										>
											Total Principal
										</Typography>
										<Typography style={{ fontSize: 9 }}>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(totalCapConsol)}
										</Typography>
									</Stack>

									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 10
											}}
										>
											Total Interes Corriente
										</Typography>
										<Typography style={{ fontSize: 9 }}>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(totalICConsol)}
										</Typography>
									</Stack>

									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 10
											}}
										>
											Total Monto a Pagar
										</Typography>
										<Typography style={{ fontSize: 9 }}>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(totalMontoPagaConsol)}
										</Typography>
									</Stack>
								</Stack>
								<hr />
							</Stack>
						)}
					</Container>
				</PrintReport>
			</div>
		</div>
	);
};

export default Desembolsos;
